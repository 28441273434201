import { createContext } from 'react'
import { TermsModalContextValue } from './types'

export const TermsModalContext = createContext<TermsModalContextValue>([
  {
    isOpen: false,
  },
  () => {
    console.warn('TermsModalContext not initialized')
  },
])
