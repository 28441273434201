import React, { FC } from 'react'
import { WindowLocation } from '@reach/router'
import { LocationContext } from '../../lib/pages'

export interface LocationContextProviderProps {
  location: WindowLocation
}

export const LocationContextProvider: FC<LocationContextProviderProps> = ({
  children,
  location,
}) => {
  return <LocationContext.Provider value={location}>{children}</LocationContext.Provider>
}
