import { Subject, Subscription, Observer } from 'rxjs'

/**
 * The QueueingSubject is very similar to the ReplaySubject but it only
 * queues values when it has no observers. The queued values are delivered
 * to the first observer that subscribes at which point the queue is reset.
 *
 * Forked from: https://github.com/insidewhy/queueing-subject
 */
export class QueueingSubject<T> extends Subject<T> {
  private queuedValues: T[] = []

  constructor(private queueSize = Infinity) {
    super()
    this.queueSize = Math.max(1, queueSize)
  }

  next(value: T): void {
    if (this.closed || this.observed) {
      super.next(value)
    } else {
      this.queuedValues.push(value)
      this.trimQueue()
    }
  }

  subscribe(observer?: Partial<Observer<T>>): Subscription
  subscribe(next: (value: T) => void): Subscription
  /** @deprecated Instead of passing separate callback arguments, use an observer argument. Signatures taking separate callback arguments will be removed in v8. Details: https://rxjs.dev/deprecations/subscribe-arguments */
  subscribe(
    next?: ((value: T) => void) | null,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error?: ((error: any) => void) | null,
    complete?: (() => void) | null
  ): Subscription

  subscribe(
    observerOrNext?: Partial<Observer<T>> | ((value: T) => void) | null,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error?: ((error: any) => void) | null,
    complete?: (() => void) | null
  ): Subscription {
    if (observerOrNext == null) {
      return super.subscribe(null, error, complete)
    }

    const subscription =
      typeof observerOrNext === 'function'
        ? super.subscribe(observerOrNext, error, complete)
        : super.subscribe(observerOrNext)

    if (!subscription.closed) {
      this.tryFlush(observerOrNext, error)
    }
    return subscription
  }

  protected tryFlush(
    observerOrNext: Partial<Observer<T>> | ((value: T) => void),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: ((error: any) => void) | null | undefined
  ): void {
    const { queuedValues } = this
    if (!queuedValues.length) {
      return
    }

    try {
      queuedValues.forEach((value) => super.next(value))
      queuedValues.splice(0)
    } catch (err) {
      if (typeof observerOrNext === 'function') {
        error?.(err)
      } else {
        observerOrNext.error?.(err)
      }
    }
  }

  protected trimQueue(): void {
    const { queuedValues, queueSize } = this
    if (queueSize < Infinity && queueSize < queuedValues.length) {
      queuedValues.splice(0, queuedValues.length - queueSize)
    }
  }
}
