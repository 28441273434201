import { AppBar, Grid } from '@material-ui/core'
import styled from 'styled-components'

export const TopBar = styled(AppBar)`
  && {
    height: 65px;
    position: relative;
  }
`

export const MainGrid = styled(Grid)`
  && {
    padding: 30px 15px;
  }
`
