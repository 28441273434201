import styled from 'styled-components'
import { ButtonLink } from '@designsforhealth/dfh-react-components'

export const LogoImage = styled.img`
  margin: 0 0 0 15px;
  width: 193px;
  @media (min-width: 600px) {
    margin: 0 auto;
  }
`

export const LogoButton = styled(ButtonLink)`
  display: block;
  margin: 0 auto;
`
