import React, { VFC } from 'react'
import { Helmet } from 'react-helmet-async'

import appleTouchIcon from '../../images/favicons/apple-touch-icon.png'
import favicon16px from '../../images/favicons/16px.png'
import favicon32px from '../../images/favicons/32px.png'
import faviconIco from '../../images/favicons/favicon.ico'

export const Head: VFC = () => (
  <Helmet titleTemplate="%s - Designs for Health" defaultTitle="Accounts | Designs for Health">
    <meta
      name="viewport"
      content="minimum-scale=1,maximum-scale=1, initial-scale=1, width=device-width"
    />
    <link href={appleTouchIcon} rel="apple-touch-icon" />
    <link href={favicon16px} rel="icon" sizes="16x16" />
    <link href={favicon32px} rel="icon" sizes="32x32" />
    <link href={faviconIco} rel="icon" sizes="48x48" />
  </Helmet>
)
