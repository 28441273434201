import React, { VFC } from 'react'
import { Helmet } from 'react-helmet-async'
import styled from 'styled-components'
import { Head } from './Head'
import { CheckoutGlobalStyle } from '../checkout/CheckoutGlobalStyle'
import { CheckoutLayout } from '../checkout/CheckoutLayout'

const Container = styled.div`
  text-align: center;
`

const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.component.link};
`

export const InvalidCallbackPage: VFC = () => {
  return (
    <>
      <Head />
      <Helmet title={'Redirect Error'} />
      <CheckoutGlobalStyle />
      <CheckoutLayout>
        <Container>
          <h1>Failed to redirect back to store</h1>
          <p>An error occurred while attempting to redirect back to the application.</p>
          <p>
            Please go back and retry, or contact our Customer Experience team at:{' '}
            <StyledLink href="tel:+18606236314">(860) 623-6314</StyledLink>
          </p>
        </Container>
      </CheckoutLayout>
    </>
  )
}
