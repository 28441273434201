import { useAuth0 } from '@auth0/auth0-react'
import React, { useEffect } from 'react'
import { setHubSpotTrackingOperation } from '../../lib/hubspot-tracking/state'

const HubSpotTrackingIdentify: React.FC = () => {
  const { isAuthenticated, user } = useAuth0()
  useEffect(() => {
    if (!isAuthenticated || !user) {
      return
    }
    setHubSpotTrackingOperation({
      key: 'identify',
      params: [
        {
          email: user.email,
        },
      ],
    })
  }, [isAuthenticated, user])

  return null
}

export default HubSpotTrackingIdentify
