import React, { FC, ReactNode } from 'react'
import {
  useTermsModalContextProvider,
  TermsModalContext,
  TermsModalDispatchRef,
} from '../../lib/terms'

export interface TermsModalContextProviderProps {
  children: ReactNode
  dispatchRef?: TermsModalDispatchRef
}

export const TermsModalContextProvider: FC<TermsModalContextProviderProps> = ({
  children,
  dispatchRef,
}) => {
  const [state, dispatch] = useTermsModalContextProvider({ dispatchRef })

  return (
    <TermsModalContext.Provider value={[state, dispatch]}>{children}</TermsModalContext.Provider>
  )
}
