export const encodeUrlForAuth0ExtParam = (url: string): string =>
  url
    .replace(/\?/g, '~@')
    .replace(/=/g, '~-')
    .replace(/&/g, '~+')
    .replace(/%/g, '~*')
    .replace(/#/g, '~~')

export const decodeUrlFromAuth0ExtParam = (encodedUrl: string): string =>
  encodedUrl
    .replace(/~@/g, '?')
    .replace(/~-/g, '=')
    .replace(/~\+/g, '&')
    .replace(/~\*/g, '%')
    .replace(/~~/g, '#')
