import { Auth0Context, Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react'
import { WindowLocation } from '@reach/router'
import { flush as sentryFlush } from '@sentry/gatsby'
import React, { useMemo } from 'react'
import { useAuthCallback } from '../../lib/auth'
import { useLocation } from '../../lib/pages'
import { encodeUrlForAuth0ExtParam } from '../../lib/auth/auth0-ext-params'
import { logAndCaptureException } from '../../lib/monitoring'

class SignUpUrlTooLongError extends Error {
  name = 'SignUpUrlTooLongError'

  constructor(
    which: 'preferred' | 'fallback',
    public readonly encodedLength: number,
    public readonly signUpUrl: string
  ) {
    // 'Error' breaks prototype chain here
    super(
      `${
        which.charAt(0).toUpperCase() + which.substring(1)
      } \`ext-signup-url\` value exceeds Auth0 external parameter limit: ${encodedLength} characters`
    )

    // restore prototype chain
    // NOTE: watch for babel transform errors with `new.target`
    Object.setPrototypeOf(this, new.target.prototype)
  }

  get [Symbol.toStringTag](): string {
    return this.name
  }
}

const signupUrlParam = async (
  location: WindowLocation,
  callbackUrl: string | undefined
): Promise<string | undefined> => {
  const encodedHref = encodeUrlForAuth0ExtParam(location.href)
  const paramLength = encodeURIComponent(encodedHref).length
  if (paramLength <= 255) {
    return encodedHref
  }
  logAndCaptureException(new SignUpUrlTooLongError('preferred', paramLength, encodedHref))
  await sentryFlush(300)

  // Fallback to the callback URL if the current URL is too long. Although not ideal, this should
  // help the user return to a familiar location (e.g. dispensary) from which they can restart the
  // login/signup flow.
  if (!callbackUrl) return undefined
  const encodedCallbackUrl = encodeUrlForAuth0ExtParam(callbackUrl)
  const fallbackParamLength = encodeURIComponent(encodedCallbackUrl).length
  if (fallbackParamLength <= 255) {
    return encodedCallbackUrl
  }

  logAndCaptureException(
    new SignUpUrlTooLongError('fallback', fallbackParamLength, encodedCallbackUrl)
  )
  await sentryFlush(300)
  return undefined
}

export const Auth0ContextOverrideProvider: React.FC = ({ children }) => {
  const { loginWithRedirect, ...auth0ContextRest } = useAuth0()
  const { getCallbackUrl, getLoginRedirectUrl } = useAuthCallback()
  const location = useLocation()

  const contextValue = useMemo(
    (): Auth0ContextInterface => ({
      ...auth0ContextRest,
      loginWithRedirect: (options) =>
        signupUrlParam(location, getCallbackUrl()?.href).then((extSignupUrl) =>
          loginWithRedirect({
            ...options,
            'ext-signup-url': extSignupUrl,
            redirectUri: getLoginRedirectUrl(),
          })
        ),
    }),
    [auth0ContextRest, getCallbackUrl, getLoginRedirectUrl, location, loginWithRedirect]
  )

  return <Auth0Context.Provider value={contextValue}>{children}</Auth0Context.Provider>
}
