import { stringify } from 'query-string'
import React, { useEffect, useMemo, useRef } from 'react'
import { useId } from '@reach/auto-id'
import styled from 'styled-components'
import {
  isOnHubSpotTrackingReadyMessage,
  isSandboxCallbackMessage,
  thirdPartySandboxUrl,
} from '../../lib/third-party-sandbox'
import { HubSpotTrackingOperation, hubSpotTrackingQueue$ } from '../../lib/hubspot-tracking/state'
import { Observer, Subscription } from 'rxjs'
import { sanitizeLocation } from '../../lib/auth/sanitize'

const hubSpotPortalId = process.env.GATSBY_HUBSPOT_PORTAL_ID

interface HubSpotTrackingMessage {
  id: string
  key: string
  pageInfo: HubSpotTrackingPageInfo
  params?: ReadonlyArray<unknown>
  type: 'hubSpotTracking'
}

interface HubSpotTrackingPageInfo {
  pageUrl?: string
  pageTitle?: string
  referrer?: string
}

// adapted from HS tracking code
const getReferrer = (): string => {
  if (typeof window === 'undefined') {
    return ''
  }
  try {
    const topReferrer = window.top ? window.top.document.referrer : undefined
    if (topReferrer) {
      return topReferrer
    }
  } catch (e) {
    try {
      const parentReferrer = window.parent ? window.parent.document.referrer : undefined
      if (parentReferrer) {
        return parentReferrer
      }
    } catch (e) {
      // no-op
    }
  }
  return window.document.referrer
}

const pushOperationsToSandbox = ({
  iframeIdRef,
  iframeRef,
}: {
  iframeIdRef: React.MutableRefObject<string | undefined>
  iframeRef: React.RefObject<HTMLIFrameElement>
}): Partial<Observer<HubSpotTrackingOperation>> => ({
  next(operation) {
    const iframeWindow = iframeRef.current?.contentWindow
    if (!iframeWindow) {
      console.error(`[pushOperationsToSandbox] iframe contentWindow not available`)
      return
    }
    const iframeId = iframeIdRef.current
    if (!iframeId) {
      console.error(`[pushOperationsToSandbox] iframeId not available`)
      return
    }
    const location = typeof window !== 'undefined' ? sanitizeLocation(window.location) : undefined
    if (!location) {
      console.error(`[pushOperationsToSandbox] location not available`)
      return
    }

    // coerce operation to array since operation may be tuple of operations
    const individualOperations = Array.isArray(operation) ? operation : [operation]

    const outboundMessages: HubSpotTrackingMessage[] = individualOperations.map(
      (individualOperation) => ({
        ...individualOperation,
        type: 'hubSpotTracking',
        id: iframeId,
        pageInfo: {
          pageUrl: location.href,
          referrer: getReferrer(),
        },
      })
    )

    outboundMessages.forEach((outboundMessage) => {
      // console.log(`[pushOperationsToSandbox] sending message`)
      iframeWindow.postMessage(outboundMessage, thirdPartySandboxUrl)
    })
  },
  error(err) {
    console.error(`[pushOperationsToSandbox]`, err)
  },
})

type IFrameProps = React.ComponentPropsWithoutRef<'iframe'>

export type HubSpotTrackingIFrameProps = Omit<IFrameProps, 'sandbox' | 'src' | 'style' | 'title'>

const HubSpotTrackingIFrame: React.VFC<HubSpotTrackingIFrameProps> = ({ ...rest }) => {
  const iframeIdValue = useId()
  const iframeId = iframeIdValue ? `hs-tracking-${iframeIdValue}` : undefined
  const iframeRef = useRef<HTMLIFrameElement>(null)

  // store iframeId in ref for access from subscriber callbacks
  const iframeIdRef = useRef(iframeId)
  useEffect(() => {
    iframeIdRef.current = iframeId
  }, [iframeId])

  const subscriptionRef = useRef<Subscription | undefined>(undefined)

  useEffect(() => {
    if (!iframeId) {
      return
    }
    const onHubSpotTrackingReaderListener = (ev: MessageEvent): void => {
      if (ev.origin !== thirdPartySandboxUrl) {
        return
      }
      const { data: message } = ev
      if (
        !isSandboxCallbackMessage(message) ||
        !isOnHubSpotTrackingReadyMessage(message) ||
        message.id !== iframeId
      ) {
        return
      }

      subscriptionRef.current = hubSpotTrackingQueue$.subscribe(
        pushOperationsToSandbox({ iframeRef, iframeIdRef })
      )
      // console.log(`[HubSpotTrackingIFrame] tracking ready`)
    }
    window.addEventListener('message', onHubSpotTrackingReaderListener)
    return () => {
      window.removeEventListener('message', onHubSpotTrackingReaderListener)
      const subscription = subscriptionRef.current
      if (subscription) {
        subscription.unsubscribe()
        subscriptionRef.current = undefined
      }
    }
  }, [iframeId])

  const iframeSrc = useMemo(
    () =>
      hubSpotPortalId && iframeId && typeof window !== 'undefined' && window.location.origin
        ? `${thirdPartySandboxUrl}/hs/tracking.html?${stringify({
            id: iframeId,
            o: window.location.origin,
            p: hubSpotPortalId,
          })}`
        : undefined,
    [iframeId]
  )

  return iframeSrc ? (
    <iframe
      ref={iframeRef}
      scrolling="no"
      src={iframeSrc}
      sandbox="allow-scripts allow-same-origin"
      title="HubSpot Tracking"
      {...rest}
    />
  ) : null
}

const StyledHubSpotTrackingIFrame = styled(HubSpotTrackingIFrame)`
  position: absolute;
  width: 1px;
  height: 1px;
  left: -100%;
  border: none;
  display: block;
  overflow: hidden;
`

export default StyledHubSpotTrackingIFrame
