import { useEffect, useReducer } from 'react'
import {
  TermsModalAction,
  TermsModalContextValue,
  TermsModalDispatchRef,
  TermsModalState,
} from '../types'

export interface UseTermsModalContextProviderOptions {
  dispatchRef?: TermsModalDispatchRef
}

export const useTermsModalContextProvider = ({
  dispatchRef,
}: UseTermsModalContextProviderOptions): TermsModalContextValue => {
  const [state, dispatch] = useReducer(
    (prevState: TermsModalState, action: TermsModalAction) => {
      switch (action.type) {
        case 'open':
          return { ...prevState, isOpen: true }
        case 'close':
          return { ...prevState, isOpen: false }
        case 'toggleOpen':
          return { ...prevState, isOpen: !prevState.isOpen }
        default:
          console.error(`Invalid action (TermsModalContextProvider): ${JSON.stringify(action)}`)
          return prevState
      }
    },
    { isOpen: false },
    undefined
  )

  useEffect(() => {
    if (dispatchRef) {
      dispatchRef.current = dispatch
    }
    return () => {
      if (dispatchRef) {
        dispatchRef.current = null
      }
    }
  }, [dispatchRef])

  return [state, dispatch]
}
