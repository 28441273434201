import React from 'react'
import { navigate } from 'gatsby'
import { Auth0Provider } from '@auth0/auth0-react'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'

import '@fontsource/herr-von-muellerhoff'
import '@fontsource/roboto/latin.css'
import '@fontsource/roboto-condensed/latin.css'
import '@designsforhealth/dfh-fonts-gotham'
import '@designsforhealth/dfh-fonts-gotham-condensed'
import '@designsforhealth/dfh-fonts-gotham-narrow'
import '@designsforhealth/dfh-fonts-gotham-xnarrow'

import theme from './src/theme'
import customTheme from './src/theme/mui'
import { auth0Config } from './src/lib/auth'
import {
  ConfigParamsContextProvider,
  LocationContextProvider,
  TermsModalContextProvider,
} from './src/components/context'
import { StyledModalProvider } from './src/components/context/StyledModalProvider'
import { Page } from './src/components/global/Page'
import { CountriesContextProvider } from './src/components/context/CountriesContextProvider'
import HubSpotTrackingIdentify from './src/components/third-party-sandbox/HubSpotTrackingIdentify'
import HubSpotTrackingIFrame from './src/components/third-party-sandbox/HubSpotTrackingIFrame'
import { setHubSpotTrackingOperation } from './src/lib/hubspot-tracking/state'
import { sanitizeLocation } from './src/lib/auth/sanitize'
import { Auth0ContextOverrideProvider } from './src/components/context/Auth0ContextOverrideProvider'

const onRedirectCallback = (appState) => {
  // Use Gatsby's navigate method to replace the url
  navigate(appState?.returnTo || '/', { replace: true })
}

export const onRouteUpdate = ({ location }) => {
  const trackPageView = () => {
    if (!location) {
      return
    }
    const sanitized = sanitizeLocation(location)
    const path = `${sanitized.pathname}${sanitized.search}${sanitized.hash}`
    setHubSpotTrackingOperation([{ key: 'setPath', params: [path] }, { key: 'trackPageView' }])
  }

  // follow behavior of gatsby-plugin-hubspot
  if (typeof window.requestAnimationFrame === 'function') {
    window.requestAnimationFrame(() => {
      window.requestAnimationFrame(trackPageView)
    })
  } else {
    // simulate 2 requestAnimationFrame calls
    setTimeout(trackPageView, 32)
  }
}

export const wrapRootElement = ({ element }) => {
  return (
    <Auth0Provider
      domain={auth0Config.domain}
      clientId={auth0Config.clientId}
      audience={auth0Config.audience}
      scope={auth0Config.scope}
      onRedirectCallback={onRedirectCallback}
    >
      <StyledThemeProvider theme={theme}>
        <MuiThemeProvider theme={customTheme}>
          {element}
          <HubSpotTrackingIdentify />
          <HubSpotTrackingIFrame />
        </MuiThemeProvider>
      </StyledThemeProvider>
    </Auth0Provider>
  )
}

export const wrapPageElement = ({ element, props, ...rest }) => {
  return (
    <CountriesContextProvider countries={props.pageContext?.countries || []}>
      <LocationContextProvider location={props.location}>
        <ConfigParamsContextProvider>
          <Auth0ContextOverrideProvider>
            <StyledModalProvider>
              <TermsModalContextProvider>
                <Page>{element}</Page>
              </TermsModalContextProvider>
            </StyledModalProvider>
          </Auth0ContextOverrideProvider>
        </ConfigParamsContextProvider>
      </LocationContextProvider>
    </CountriesContextProvider>
  )
}
