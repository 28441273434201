import React from 'react'
import * as Styled from './styled'
import { useAuthCallback } from '../../../lib/auth'

interface LeaveCheckoutModalProps {
  isOpen: boolean
  closeModal: () => void
}

export const LeaveCheckoutModal: React.FC<LeaveCheckoutModalProps> = ({ isOpen, closeModal }) => {
  const { returnToCallback } = useAuthCallback()
  return (
    <Styled.Modal isOpen={isOpen} onBackgroundClick={closeModal} onEscapeKeydown={closeModal}>
      <Styled.ConfirmationText>Are you sure you want to leave checkout?</Styled.ConfirmationText>
      <Styled.StayInCheckoutButton onClick={closeModal}>
        Stay in checkout
      </Styled.StayInCheckoutButton>
      <Styled.ContinueShoppingButton onClick={returnToCallback}>
        Continue shopping
      </Styled.ContinueShoppingButton>
    </Styled.Modal>
  )
}
