import React, { FC } from 'react'
import { CountriesContext, Country } from '../../lib/countries'

export interface CountriesContextProviderProps {
  countries: Country[]
}

export const CountriesContextProvider: FC<CountriesContextProviderProps> = ({
  children,
  countries,
}) => {
  return <CountriesContext.Provider value={{ countries }}>{children}</CountriesContext.Provider>
}
