import { Grid, Tab, Tabs } from '@material-ui/core'
import styled, { css } from 'styled-components'

export const CheckoutNavigationBar = styled(Grid)`
  ${({ theme }) => css`
    && {
      flex: auto;
      flex-grow: 1;
      margin: -30px -15px 20px;
      max-width: none;

      @media (min-width: ${theme.breakpoints.sm}) {
        border: 1px solid #e5e5e5;
        border-bottom: none;
        border-radius: 4px;
        margin: 0 0 20px;
        max-width: 100%;
      }
    }
  `}
`

export const NavigationTabs = styled(Tabs)`
  && {
    .checkout-navigation-tab-indicator {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
`

export const NavigationTab = styled(Tab)`
  ${({ theme }) => css`
    && {
      border-bottom: 1px solid ${theme.colors.grayscale.gray10};
      font-size: 0.75rem;
      min-width: 0;
      padding: 6px 0 6px;

      @media (min-width: ${theme.breakpoints.sm}) {
        &:first-child {
          border-bottom-left-radius: 3px;
        }

        &:last-child {
          border-bottom-right-radius: 3px;
        }
      }
    }
  `}
`

export const NavigationTabCurrentOrCompleted = styled(NavigationTab)`
  ${({ theme }) => css`
    && {
      font-weight: bold;
      // Material UI 'dims' inactive tabs via an opacity value not color so it needs to
      // be overridden to match that of the selected tab:
      opacity: 1;
      // Account for the 2px border-bottom applied to these tabs so text lines up with
      // the inactive tabs:
      padding-top: 8px;
      border-bottom: 2px solid ${theme.colors.component.backgroundDarkBlue};
    }
  `}
`
