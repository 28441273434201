const searchRegex = /&?(?:code|state)=[^&]+/g

type PickedLocation = Pick<Window['location'], 'hash' | 'href' | 'origin' | 'pathname' | 'search'>

/**
 * Remove `code` from `location.search` and token from `location.hash`
 */
export const sanitizeLocation = (location: PickedLocation): PickedLocation => {
  const { hash: rawHash, origin, pathname, search: rawSearch } = location
  const hash = rawHash.indexOf('token=') >= 0 ? '' : rawHash
  const search = rawSearch.replace(searchRegex, '')
  return {
    hash,
    href: `${origin}${pathname}${search}${hash}`,
    origin,
    pathname,
    search,
  }
}
