import React from 'react'
import styled from 'styled-components'
import { ModalProvider, BaseModalBackground } from 'styled-react-modal'

const StyledModalBackground = styled(BaseModalBackground)`
  // use percentages instead of vh/vw to constrain modal background inside scrollbars
  // - note this only works when modal background is a direct child of "body" or some other
  //   element with full width
  width: 100%;
  height: 100%;
  z-index: 10000;
`

export const StyledModalProvider: React.FC = ({ children }) => {
  return <ModalProvider backgroundComponent={StyledModalBackground}>{children}</ModalProvider>
}
