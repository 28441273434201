import { Grid, Toolbar } from '@material-ui/core'
import React, { FC } from 'react'
import { CheckoutLogo } from '../CheckoutLogo'
import { CheckoutNavigationBar } from '../CheckoutNavigationBar'
import * as Styled from './styled'

export interface CheckoutLayoutProps {
  showNavigationBar?: boolean
}

export const CheckoutLayout: FC<CheckoutLayoutProps> = ({ children, showNavigationBar }) => {
  return (
    <>
      <Styled.TopBar position="relative" elevation={0}>
        <Toolbar>
          <CheckoutLogo />
        </Toolbar>
      </Styled.TopBar>
      <Grid alignItems="flex-start" component="main" container justifyContent="center">
        <Styled.MainGrid
          alignItems="flex-start"
          container
          item
          justifyContent="center"
          md={7}
          sm={10}
          xs={12}
        >
          {showNavigationBar && <CheckoutNavigationBar />}
          {children}
        </Styled.MainGrid>
      </Grid>
    </>
  )
}
