import React, { VFC } from 'react'
import * as Styled from './styled'

// This accepts both loading and processing so the semantically-appropriate prop can be
// used whether the user is waiting for data to load or waiting on the response to an
// action taken by the user.
export interface SpinnerProps {
  loading?: boolean
  processing?: boolean
}

export const Spinner: VFC<SpinnerProps> = ({ loading, processing }) => {
  if (loading || processing) {
    return (
      <Styled.Container>
        <Styled.Contents />
      </Styled.Container>
    )
  }

  return null
}

Spinner.defaultProps = {
  loading: false,
  processing: false,
}
