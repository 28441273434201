import { captureException, captureMessage } from '@sentry/browser'

export const logAndCaptureException: typeof captureException = (exception, ...rest) => {
  console.error(exception)

  // avoid reporting to Sentry in development environment
  if (process.env.NODE_ENV === 'development') {
    return ''
  }

  return captureException(exception, ...rest)
}

export const logAndCaptureMessage: typeof captureMessage = (message, ...rest) => {
  console.error(message)

  // avoid reporting to Sentry in development environment
  if (process.env.NODE_ENV === 'development') {
    return ''
  }

  return captureMessage(message, ...rest)
}
