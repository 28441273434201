import styled, { keyframes } from 'styled-components'

const spinnerKeyframes = keyframes`
  // Start in initial position with initial size:
  0% { transform: rotate(0deg) scale(1); }

  // When animation is halfway complete spinner should have completed a half rotation and have grown slightly:
  50% { transform: rotate(180deg) scale(1.1); }

  // When animation is complete it should return to initial position and size:
  100% { transform: rotate(360deg) scale(1); }
`

export const Container = styled.div`
  margin: 0 auto;
  padding-top: 120px;
  position: relative;
`

export const Contents = styled.div`
  animation: ${spinnerKeyframes} 1s ease-in-out infinite;
  background-color: #08203c;
  background: transparent;
  border: 2px solid #08203c; // Must be before border-bottom-color and border-top-color
  border-bottom-color: transparent;
  border-radius: 50%;
  border-top-color: transparent;
  color: #08203c;
  content: '';
  display: inline-block;
  height: 50px;
  left: 50%;
  margin-left: -25px; // Half the size of the spinner to center it.
  margin-top: -25px; // Half the size of the spinner to center it.
  position: absolute;
  top: 50%;
  width: 50px;
`
