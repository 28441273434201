import { WindowLocation } from '@reach/router'
import { useContext } from 'react'
import { LocationContext } from '../context'
import { useLogAndCaptureError } from '../../monitoring'

export const useLocation = (): WindowLocation => {
  const location = useContext(LocationContext)
  const error = new Error('Location context not initialized')

  useLogAndCaptureError(!location ? error : undefined)

  if (!location) {
    throw error
  }
  return location
}
