import React, { useCallback, useState } from 'react'
import * as Styled from './styled'
import { LeaveCheckoutModal } from '../LeaveCheckoutModal'
import dfhLogo from '../../../images/designsForHealthLogo.png'

export const CheckoutLogo: React.VFC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const openModal = useCallback(() => setIsOpen(true), [])
  const closeModal = useCallback(() => setIsOpen(false), [])

  return (
    <>
      <Styled.LogoButton onClick={openModal}>
        <Styled.LogoImage alt="Designs for Health" src={dfhLogo} />
      </Styled.LogoButton>
      <LeaveCheckoutModal isOpen={isOpen} closeModal={closeModal} />
    </>
  )
}
