import { OnHubSpotTrackingReadyMessage, OnResizeMessage, SandboxCallbackMessage } from './types'

export function isSandboxCallbackMessage(value: unknown): value is SandboxCallbackMessage<string> {
  if (!value || typeof value !== 'object') {
    return false
  }
  const partial = value as Partial<SandboxCallbackMessage<string>>
  return (
    partial.type === 'dfhCallback' &&
    typeof partial.id === 'string' &&
    typeof partial.eventName === 'string'
  )
}

export function isOnHubSpotTrackingReadyMessage(
  value: SandboxCallbackMessage<string>
): value is OnHubSpotTrackingReadyMessage {
  return value.eventName === 'onHubSpotTrackingReady'
}

export function isOnResizeMessage(value: SandboxCallbackMessage<string>): value is OnResizeMessage {
  const valuePartial = value as SandboxCallbackMessage<string> & { data?: unknown }
  if (
    value.eventName !== 'onResize' ||
    valuePartial.data == null ||
    typeof valuePartial.data !== 'object'
  ) {
    return false
  }
  const dataPartial = valuePartial.data as Record<string, unknown>
  return typeof dataPartial.width === 'number' && typeof dataPartial.height === 'number'
}
