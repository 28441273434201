import { useMemo } from 'react'
import { AuthCallbackState } from '../types'
import { useAuthCallbackState } from './useAuthCallbackState'

export interface UseAuthCallback {
  state?: AuthCallbackState
  isValidCallback: boolean
  getCallbackUrl: () => URL | undefined
  getLoginRedirectUrl: () => string
  returnToCallback: () => void
}

export const useAuthCallback = (): UseAuthCallback => {
  const state = useAuthCallbackState()

  return useMemo<UseAuthCallback>(() => {
    const callbackUrl = state?.callbackUrl

    return {
      getCallbackUrl: () => callbackUrl,
      getLoginRedirectUrl: () =>
        `${window.location.origin}?callbackUrl=${encodeURIComponent(callbackUrl?.href ?? '')}`,
      isValidCallback: !!state,

      returnToCallback: () => {
        if (!callbackUrl) {
          return
        }
        window.location.href = callbackUrl.href
      },
    }
  }, [state])
}
