import { DbSignUpOptions, WebAuth } from 'auth0-js'
import { auth0Config } from './config'

export const auth0Client = new WebAuth({
  domain: auth0Config.domain,
  clientID: auth0Config.clientId,
  audience: auth0Config.audience,
  scope: auth0Config.scope,
  state: '123abc',
})

export interface SignUpOptions<TUserMetadata = undefined> extends DbSignUpOptions {
  given_name: string
  family_name: string
  user_metadata: TUserMetadata
}

export interface SignUpResponse<TUserMetadata = undefined> {
  _id: string
  email_verified: boolean
  email: string
  user_metadata: TUserMetadata
}

export const signup = async <TUserMetadata = undefined>(
  options: SignUpOptions<TUserMetadata>
): Promise<SignUpResponse<TUserMetadata>> => {
  return new Promise<SignUpResponse<TUserMetadata>>((resolve, reject) => {
    auth0Client.signup(options, (error, result) => {
      if (error) {
        reject(error)
      } else {
        resolve(result)
      }
    })
  })
}

export const loginWithCredentials = async ({
  email,
  password,
  redirectUri,
}: {
  email: string
  password: string
  redirectUri: string
}): Promise<void> => {
  return new Promise((resolve, reject) => {
    auth0Client.login(
      {
        email,
        password,
        // use `code` to avoid receiving token hash in redirect URI
        responseType: 'code',
        redirectUri,
      },
      (error, result) => {
        if (error) {
          reject(error)
        } else {
          resolve(result)
        }
      }
    )
  })
}
