import { extendedTheme } from '../extended'
// import { TypographyOptions } from '@material-ui/core/styles/createTypography'

// To match Material UI's theming all fontSize values should use rem units.
const remFromPx = (value: number) => `${value / 16}rem`

const typography = {
  button: {
    fontFamily: extendedTheme.fontFamilies.robotoCondensed,
    fontSize: remFromPx(15),
    fontStretch: 'condensed',
    fontStyle: 'normal',
    fontWeight: 'normal',
    height: '33px',
    letterSpacing: '0.9px',
    lineHeight: 'normal',
  },
  fontFamily: extendedTheme.fontFamilies.roboto,
  h4: {
    color: '#000000',
    fontSize: remFromPx(15),
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'bold',
    letterSpacing: 'normal',
    lineHeight: 'normal',
  },
  h5: {
    color: '#000000',
    fontFamily: extendedTheme.fontFamilies.robotoCondensed,
    fontSize: remFromPx(14),
    fontStretch: 'condensed',
    fontStyle: 'normal',
    fontWeight: 'bold',
    letterSpacing: '0.84px',
    lineHeight: 'normal',
    marginBottom: '20px',
  },
} as const

export default typography
