import { createGlobalStyle } from 'styled-components'
import { globalStyles, normalize } from '@designsforhealth/dfh-react-components'

/**
 * Global styles for checkout pages. Imports global styles (with normalize) from
 * `dfh-react-components` and reduces default font size to 14px.
 */
export const CheckoutGlobalStyle = createGlobalStyle(
  () => normalize,
  ({ theme }) => globalStyles(theme),
  () => ({
    body: {
      fontSize: '0.875em',
    },
  })
)
