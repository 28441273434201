import { useEffect, useMemo, useState } from 'react'
import queryString from 'query-string'
import { useLocation } from '../../pages'
import { ConfigParams } from '../types'

const firstOrIdentity = (x: string | (string | null)[] | undefined): string | undefined =>
  Array.isArray(x) ? x[0] ?? undefined : x ?? undefined

const trimIfString = (x: string | undefined): string | undefined => x && x.trim()

const parseParam: (x: (string | null)[] | string | null) => string | undefined = (x) =>
  trimIfString(firstOrIdentity(x ?? undefined))

export const useConfigParamsContextProvider = (): ConfigParams => {
  const { search } = useLocation()

  const parsedParams = useMemo<ConfigParams>(() => {
    const { returnTo, callbackUrl, practitionerCode } = queryString.parse(search || '')
    return {
      returnTo: parseParam(returnTo),
      callbackUrl: parseParam(callbackUrl),
      practitionerCode: parseParam(practitionerCode),
    }
  }, [search])

  const [configParams, setConfigParams] = useState<ConfigParams>(parsedParams)

  useEffect(() => {
    setConfigParams((prevParams) =>
      Object.entries(parsedParams).reduce((acc: ConfigParams, [key, value]) => {
        // if parsed params are emptied, keep the values in state, but if url params updated  with non-empty values, update the state
        if (!value) {
          return acc
        }
        return {
          ...acc,
          [key]: value,
        }
      }, prevParams)
    )
  }, [parsedParams])

  return configParams
}
