import { useMemo } from 'react'
import { logAndCaptureException } from '../../monitoring'
import { useConfigParams } from '../../config'
import { normalizePath } from '../utils'
import { AuthCallbackState } from '../types'

export const useAuthCallbackState = (): AuthCallbackState | undefined => {
  const configParams = useConfigParams()

  return useMemo<AuthCallbackState | undefined>(() => {
    const returnToParam = configParams.returnTo
    const callbackUrlParam = configParams.callbackUrl
    // TODO default to generating the domain and root path for returnTo
    // only update state if `callbackUrlParam` URL param has a value
    if (!callbackUrlParam) {
      return
    }
    try {
      const returnToPath = normalizePath(returnToParam ? `${returnToParam}` : '') || ''

      return {
        returnToPath,
        callbackUrl: new URL(`${callbackUrlParam}`),
      }
    } catch (error) {
      logAndCaptureException(error)
    }
  }, [configParams.returnTo, configParams.callbackUrl])
}
