import React, { useMemo, VFC } from 'react'
import * as Styled from './styled'

// Copied directly from a Material UI example. May not be
// exactly what we want, but it's a start:
const a11yProps = ({ index }: { index: number }) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
})

export const CheckoutNavigationBar: VFC = () => {
  const selectedOption = 'Login / Register'
  const navigationBarOptions = [selectedOption, 'Shipping', 'Delivery', 'Payment', 'Review'].map(
    (label, index) => ({ label, value: index })
  )

  const checkoutNavigationBarTabValue = useMemo(() => {
    return (
      navigationBarOptions.find(({ label, value }) => {
        return selectedOption === label
      })?.value || 0
    )
  }, [navigationBarOptions])

  return (
    <Styled.CheckoutNavigationBar item xs={12}>
      <Styled.NavigationTabs
        centered
        // need to use class for indicator, since MUI doesn't allow providing a component for it,
        // therefore it's made specific enough to avoid conflict
        classes={{ indicator: 'checkout-navigation-tab-indicator' }}
        value={checkoutNavigationBarTabValue}
        variant="fullWidth"
      >
        {navigationBarOptions.map((navOption) => {
          const isCurrentOrComplete = navOption.value <= checkoutNavigationBarTabValue
          const NavigationTab = isCurrentOrComplete
            ? Styled.NavigationTabCurrentOrCompleted
            : Styled.NavigationTab
          return (
            <NavigationTab
              disabled={!isCurrentOrComplete}
              key={navOption.value}
              label={navOption.label}
              {...a11yProps({ index: navOption.value })}
            />
          )
        })}
      </Styled.NavigationTabs>
    </Styled.CheckoutNavigationBar>
  )
}
