import React, { FC, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useAuthCallback } from '../../lib/auth'
import { LoadingPage } from './LoadingPage'
import { InvalidCallbackPage } from './InvalidCallbackPage'

export const Page: FC = ({ children }) => {
  const { isLoading, isAuthenticated } = useAuth0()
  const { returnToCallback, isValidCallback } = useAuthCallback()

  useEffect(() => {
    if (isValidCallback && isAuthenticated) {
      returnToCallback()
    }
  }, [isValidCallback, isAuthenticated, returnToCallback])

  if (isLoading) {
    return <LoadingPage />
  }

  if (isAuthenticated) {
    if (isValidCallback) {
      return <LoadingPage />
    }
    return <InvalidCallbackPage />
  }

  return <>{children}</>
}
