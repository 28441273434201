export const auth0Config = {
  domain: `${process.env.GATSBY_AUTH0_DOMAIN}`,
  clientId: `${process.env.GATSBY_AUTH0_CLIENTID}`,
  audience: `${process.env.GATSBY_AUTH0_AUDIENCE}`,
  connection: 'Username-Password-Authentication',
  scope:
    'openid profile email read:professional_library read:customers update:customers magento_user accept_agreement:customers',
} as const

export const reCaptchaSiteKey: string = process.env.GATSBY_GOOGLE_RECAPTCHA_KEY ?? ''
