import React, { VFC } from 'react'
import { Helmet } from 'react-helmet-async'
import { Spinner } from '../global/Spinner'
import { CheckoutLayout } from './CheckoutLayout'
import { Head } from '../global/Head'
import { CheckoutGlobalStyle } from './CheckoutGlobalStyle'

export interface CheckoutLoadingPageProps {
  title?: string
}

export const CheckoutLoadingPage: VFC<CheckoutLoadingPageProps> = ({ title = 'Loading...' }) => {
  return (
    <>
      <Head />
      <Helmet title={title} />
      <CheckoutGlobalStyle />
      <CheckoutLayout>
        <Spinner loading />
      </CheckoutLayout>
    </>
  )
}
