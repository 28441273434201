import type { RedirectLoginOptions } from '@auth0/auth0-react'
import { Auth0Error } from 'auth0-js'
import { AuthCallbackState } from './types'

const baseUrl = typeof window !== 'undefined' ? window.location.origin : ''

export const stringStartsWith = (str: string, prefix: string): boolean => {
  return str.lastIndexOf(prefix, 0) === 0
}

/** Returns provided path starting with forward slash "/", or an empty string */
export const normalizePath = (path?: string | null): string => {
  // add "/" if not already exists, and default to empty string
  return path ? (stringStartsWith(path, '/') ? path : `/${path}`) : ''
}

export const getUrlForNormalizedPath = (normalizedPath: string): string => {
  return `${baseUrl}${normalizedPath}`
}

export const mergeLoginWithRedirectOptions = (
  options?: RedirectLoginOptions,
  authCallbackState?: AuthCallbackState
): RedirectLoginOptions => {
  const optionsObj = options || {}
  const { callbackUrl, returnToPath } = authCallbackState || {}
  const redirectUri =
    typeof window !== 'undefined' ? callbackUrl?.href || window.location.origin : ''
  return {
    ...optionsObj,
    redirectUri,
    appState: {
      ...(optionsObj.appState || {}),
      returnTo: returnToPath || undefined,
    },
  }
}

export const isAuth0Error = (error: unknown): error is Auth0Error => {
  if (!error || typeof error !== 'object') {
    return false
  }
  return !!error && 'name' in error && 'code' in error
}

export const parseAuth0Error = (error: unknown): Auth0Error | Error => {
  if (!error) {
    return new Error('Unexpected error occurred while attempting sign up.')
  }
  if (error instanceof Error) {
    return error
  }
  if (isAuth0Error(error)) {
    return error
  }
  return new Error(`Unexpected error occurred while attempting sign up. Error: ${error}`)
}
