import { theme as dfhTheme } from '@designsforhealth/dfh-react-components'

// add any project specific colors, font families, etc. here
export const extendedTheme = {
  ...dfhTheme,
  colors: {
    ...dfhTheme.colors,
    component: {
      ...dfhTheme.colors.component,
      link: dfhTheme.colors.brand.red,
      textDark: '#051120',
      buttonDisabled: '#C9D6DF',
      checkoutBorderLight: '#D6D6D6',
    },
    coolGray: {
      ...dfhTheme.colors.coolGray,
      cool450: '#C4C7CC',
      cool550: '#A9B0B5',
    },
    brandBlue: {
      main: dfhTheme.colors.brand.blue,
      dark100: '#09203C',
      dark200: '#02162F',
      dark300: '#051120',
    },
  },
  fontFamilies: {
    ...dfhTheme.fontFamilies,
    signature: "'Herr Von Muellerhoff', cursive",
  },
  breakpoints: {
    xs: 0,
    sm: '600px',
    md: '960px',
    lg: '1280px',
    xl: '1920px',
  },
} as const

export type Theme = typeof extendedTheme
