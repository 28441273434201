import { createSignal } from '@react-rxjs/utils'
import { connectable } from 'rxjs'
import { QueueingSubject } from '../rx/QueueingSubject'

export interface HubSpotTrackingIdentify {
  key: 'identify'
  params: [
    {
      email?: string
      id?: string
      [contactProp: string]: string | undefined
    }
  ]
}

export interface HubSpotTrackingSetCanonicalUrl {
  key: 'setCanonicalUrl'
  params: [string]
}

export interface HubSpotTrackingSetContentType {
  key: 'setContentType'
  params: [string]
}

export interface HubSpotTrackingSetPath {
  key: 'setPath'
  params: [string]
}

export interface HubSpotTrackingTrackEvent {
  key: 'trackEvent'
  params: [
    {
      id: string
      value?: number
    }
  ]
}

export interface HubSpotTrackingTrackPageView {
  key: 'trackPageView'
}

export type HubSpotTrackingTrackPageViewTuple = [
  HubSpotTrackingSetPath,
  HubSpotTrackingTrackPageView
]

export type HubSpotTrackingOperation =
  | HubSpotTrackingIdentify
  | HubSpotTrackingSetCanonicalUrl
  | HubSpotTrackingSetContentType
  | HubSpotTrackingSetPath
  | HubSpotTrackingTrackEvent
  | HubSpotTrackingTrackPageViewTuple

export const [hubSpotTrackingOperation$, setHubSpotTrackingOperation] =
  createSignal<HubSpotTrackingOperation>()

export const hubSpotTrackingQueue$ = connectable(hubSpotTrackingOperation$, {
  connector: () => new QueueingSubject(10),
})

hubSpotTrackingQueue$.connect()
