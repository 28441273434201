import { useEffect } from 'react'
import { logAndCaptureException, logAndCaptureMessage } from '../utils'

export interface ConditionCallback {
  (error: Error | string): boolean
}

export const useLogAndCaptureError = (
  error?: Error | string | null,
  condition?: ConditionCallback
): void => {
  useEffect(() => {
    // execute condition callback if provided
    if (error && (!condition || condition(error))) {
      if (typeof error === 'string') {
        logAndCaptureMessage(error)
      } else {
        logAndCaptureException(error)
      }
    }
  }, [condition, error])
}
