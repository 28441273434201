import styled, { css } from 'styled-components'
import StyledReactModal from 'styled-react-modal'
import { PrimaryButton, SecondaryButton } from '@designsforhealth/dfh-react-components'

export const Modal = StyledReactModal.styled(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: ${theme.colors.brand.white};
    padding: 30px;
  `
)

export const StayInCheckoutButton = styled(SecondaryButton)`
  width: 80%;
`

export const ContinueShoppingButton = styled(PrimaryButton)`
  width: 80%;
  border: 1px solid black;
  background-color: ${({ theme }) => theme.colors.coolGray.cool250};
  color: ${({ theme }) => theme.colors.component.backgroundDarkBlue};
  margin-top: 10px;
`

export const ConfirmationText = styled.p`
  color: ${({ theme }) => theme.colors.grayscale.black};
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
`
